@import "slim-select-base";

select.ch24-select, .ss-main.ch24-select {
  @apply w-full min-h-[50px] bg-white border border-gray-400 rounded-[50px] py-2 pl-6 pr-3 hover:border-[var(--blue-600)];
}

select.ch24-select:not(.ss-main) {
  @apply h-[50px] overflow-hidden appearance-none;

  option {
    @apply hidden;
  }
}

.ss-main.locations-select {

  &:focus:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' viewBox='0 0 16 22.000002500126726'%3E%3Cpath fill='%23005ea8' d='M7.17783,21.55613c-6.05408,-9.05089 -7.17783,-9.97979 -7.17783,-13.30613c0,-4.55636 3.58171,-8.25 8,-8.25c4.41829,0 8,3.69364 8,8.25c0,3.32634 -1.12375,4.25524 -7.17783,13.30613c-0.39729,0.59185 -1.24708,0.59181 -1.64433,0zM8,11.6875c1.84096,0 3.33333,-1.53901 3.33333,-3.4375c0,-1.89849 -1.49238,-3.4375 -3.33333,-3.4375c-1.84096,0 -3.33333,1.53901 -3.33333,3.4375c0,1.89849 1.49238,3.4375 3.33333,3.4375z' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/svg%3E");
  }

  &:before {
    @apply bg-no-repeat bg-center w-5 mr-2;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' viewBox='0 0 16 22.000002500126726'%3E%3Cpath fill='%23b4b4b4' d='M7.17783,21.55613c-6.05408,-9.05089 -7.17783,-9.97979 -7.17783,-13.30613c0,-4.55636 3.58171,-8.25 8,-8.25c4.41829,0 8,3.69364 8,8.25c0,3.32634 -1.12375,4.25524 -7.17783,13.30613c-0.39729,0.59185 -1.24708,0.59181 -1.64433,0zM8,11.6875c1.84096,0 3.33333,-1.53901 3.33333,-3.4375c0,-1.89849 -1.49238,-3.4375 -3.33333,-3.4375c-1.84096,0 -3.33333,1.53901 -3.33333,3.4375c0,1.89849 1.49238,3.4375 3.33333,3.4375z' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/svg%3E");
  }
}
